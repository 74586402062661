import React, { useEffect } from "react";
import { Grommet, Page, Box, TextInput } from "grommet";

const App = () => {
  const [value, setValue] = React.useState<string | undefined>();

  const onChange = (event: any) => setValue(event.target.value);

  useEffect(() => {
    // storing input value
    if (value) {
      localStorage.setItem("value", value);
    }
  }, [value]);

  useEffect(() => {
    // get input value from past sessions
    setValue(localStorage.getItem("value") || "");
  }, []);

  return (
    <Grommet full>
      <Page kind="full" background="light-3">
        <Box fill align="center" justify="start" pad="large">
          <Box width="medium">
            <TextInput
              value={value}
              onChange={onChange}
              aria-label="Input Text"
            />
          </Box>
        </Box>
      </Page>
    </Grommet>
  );
};

export default App;
